<template>
  <div>
    <img style="width:100%" @click="add" src="../../assets/images/fc.png" alt="">
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    methods:{
      add() {
        this.$router.push('/addDivide')
      }
    },
    mounted() {
      
    }
  }
</script>

<style lang="scss" scoped>

</style>